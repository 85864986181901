/**
 * 지갑 메인 페이지에서 data 없을 경우 뿌려줌
 */
const NoData = () => {
  return (
    <div className="no_data">
      <p>No Data</p>
    </div>
  );
}

export default NoData;