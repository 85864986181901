const LOGIN_CHECK = "login/LOGIN_CHECK" as const;
const LOGIN_SUCCESS = "login/LOGIN_SUCCESS" as const;
const LOGIN_SAVE_SNS_TOKEN = "login/LOGIN_SAVE_SNS_TOKEN" as const;
const LOGIN_DELETE_SNS_TOKEN = "login/LOGIN_DELETE_SNS_TOKEN" as const;
const LOGIN_RESET_STATE = "login/LOGIN_RESET_STATE" as const;
const LOGIN_CHANGE_PASSWORD_SET = "login/LOGIN_CHANGE_PASSWORD_SET" as const;
const LOGIN_CHANGE_PASSWORD_RESET = "login/LOGIN_CHANGE_PASSWORD_RESET" as const;

type LoginAction = 
  | ReturnType<typeof loginCheck>
  | ReturnType<typeof loginSuccess>
  | ReturnType<typeof saveSnsToken>
  | ReturnType<typeof deleteSnsToken>
  | ReturnType<typeof resetLoginState>
  | ReturnType<typeof setChangePassword>
  | ReturnType<typeof resetChangePassword>


export const loginCheck = () => ({
  type: LOGIN_CHECK
});

export const loginSuccess = (diff: LoginState) => ({
  type: LOGIN_SUCCESS,
  payload: diff
})

export const saveSnsToken = (diff: {token: string, category: string, email: string}) => ({
  type: LOGIN_SAVE_SNS_TOKEN,
  payload: diff
})

export const deleteSnsToken = () => ({
  type: LOGIN_DELETE_SNS_TOKEN
})

export const resetLoginState = () => ({
  type:LOGIN_RESET_STATE
})

export const setChangePassword = (diff: {inputPassword: string, isRepwd: boolean}) => ({
  type: LOGIN_CHANGE_PASSWORD_SET,
  payload: diff
})
export const resetChangePassword =() => ({
  type: LOGIN_CHANGE_PASSWORD_RESET
})

export type LoginState = {
  token: string;
  exp: string;
  email: string;
  sns_token?: string;
  category: string;
  inputPassword?: string;
  isRepwd?: boolean;
  address?: {
    origin: string;
    trans: string;
  };
}

const initialState:LoginState = {
  token:'',
  exp: '',
  email: '',
  sns_token: '',
  category: '',
  inputPassword: '',
  isRepwd: false,
  address: {origin: '', trans: ''}
}

function login(
  state: LoginState = initialState,
  action: LoginAction
  ): LoginState {
  switch (action.type) {
    case LOGIN_CHECK:
      return { ...state }
    case LOGIN_SUCCESS:
      const data = action.payload;
      if(data.address) {
        return { ...state, token: data.token, exp: data.exp, email: data.email, category: data.category, address: data.address }
      } else {
        return { ...state, token: data.token, exp: data.exp, email: data.email, category: data.category }
      }
    case LOGIN_SAVE_SNS_TOKEN:
      return { 
        ...state, 
        sns_token: action.payload.token, 
        category: action.payload.category,
        email: action.payload.email 
      }
    case LOGIN_DELETE_SNS_TOKEN:
      state.sns_token = '';
      return { ...state }
    case LOGIN_RESET_STATE:
      state = initialState;
      return { ...state }
    case LOGIN_CHANGE_PASSWORD_SET:
      state.isRepwd = action.payload.isRepwd as boolean
      state.inputPassword = action.payload.inputPassword
      return { ...state }
    case LOGIN_CHANGE_PASSWORD_RESET:
      return { ...state, isRepwd: false, inputPassword: ''}
    default:
      return state;
  }
};

export default login;