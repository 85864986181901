import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { RootState, handleWalletMenuOpen, setWalletPage } from '../../../store'; 
import { WalletProps } from '../../../pages'; 

const WalletHeader = ({headerTitle, normal, back, menu, backHandler} : WalletProps) => {

  const dispatch = useDispatch();

  /** 지갑 메뉴 Open 여부 */
  const walletMenuOpen = useSelector((state: RootState) => state.wallet.walletMenuOpen);

  return (
    <div className={classNames(`wallet_header`, {main : menu})}>
      {
        normal 
          ? (<p className="title">{headerTitle}</p>) 
          : back 
          ? (
              <>
                <div className="back_button" onClick={backHandler}></div>
                <p className="title">{headerTitle}</p>
                <div className="empty"></div>
              </>
            ) 
          : menu
          ? (
              <>
                <p className="title">{headerTitle}</p>
                <div
                  className={classNames(`menu_button`, {open : walletMenuOpen})}
                  onClick={() => {
                    dispatch(handleWalletMenuOpen(!walletMenuOpen));
                    if (walletMenuOpen) {
                      dispatch(setWalletPage({page:'main', prevPage: ''}));
                    } else {
                      dispatch(setWalletPage({page:'menu', prevPage: '' }));
                    }
                  }}
                >
                </div>
              </>
            ) 
          : (<></>)
      }
    </div>
  );
}

export default WalletHeader;