import classNames from "classnames";

import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';

import { WalletHeader } from "../../../components";

import { WalletProps } from "../index";

import './walletNft.scss';

interface NftListProps {
  title: string;
  description: string;
}

const NftList = ({title, description} : NftListProps) => {
  return(
    <div className="nft_list">
      <p className="title">{title}</p>
      <p className="description">{description}</p>
    </div>
  );
}

const WalletNFT = ({ headerTitle, normal, back, menu, backHandler }: WalletProps) => {

  const dispatch = useDispatch();
  const { t }:{ t:any } = useTranslation();

  return(
    <div
      className={classNames(`wallet_box`, `nft`, `wallet_header`)}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <WalletHeader headerTitle={headerTitle} normal={normal} back={back} menu={menu} backHandler={backHandler}/>
      <div className="nft_container">
        <div className="media_box">
          {/* <div className="media"></div> */}
          <video className="media" src="ghost_song.mp4" controls muted></video>
          <p className="title">{t('nft_item_title')}</p>
        </div>
        <div className="list_box">
          <NftList title={t('Status')} description={t('Pending')} />
          <NftList title={t('Amount')} description={t('10.25 ZEMI')} />
          <NftList title={t('Transaction Fee')} description={t('0.5 ZEMI')} />
          <NftList title={t('Receive')} description={t('10.25 ZEMI')} /> 
        </div>
      </div>
    </div>
  );
}

export default WalletNFT;