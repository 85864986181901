import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState, connectWalletSetting, setWalletPage, setExaTokenValue } from '../../../store';
import { WalletStart, WalletLogin, WalletTerms, WalletCondition, WalletPolicy, WalletPassword, WalletMain, WalletMenu, WalletMyAccount, WalletConnected, WalletChangePassword, WalletSend, WalletSendResult, WalletReceive, WalletNFT, WalletTokens } from '../index';

import '../wallet.scss';
import { WEBSCOKET_SERVER_URI } from '../../../utils';

const Wallet = () => {
  const dispatch = useDispatch();
  const isWallet = useSelector((state:RootState) => state.wallet.open);
  const token = useSelector((state: any) => state.login.token);
  const exaToken = useSelector((state: any) => state.wallet.exaToken);
  const currentPage = useSelector((state: RootState) => state.wallet.page);
  const prevPage = useSelector((state: RootState) => state.wallet.prevPage);
  const { t } = useTranslation();

  let socket: WebSocket | null = null;
  let intervalId: any;
  const [detect, setDetect] = useState(false);
  /**
   * 소켓 연결을 관리하기 위한 hook
   * 
   * @author jslee
   * @since 2022-10-28
   */
   useEffect(() => {
    console.log("[socket] 상태", socket);
    if(isWallet && currentPage === "main") {
      // intervalId = setInterval(()=>socketController(), 3000);
      socketController();
    }
    return () => {
      console.log("clean up");
      socket?.close(1000, "success");
    }
  }, [currentPage, detect])

  const socketController = async () => {
    console.log("여기까지는 들어 옴");
    if(isWallet){
      console.log("i want to connected socket ... ");
      socket = new WebSocket(WEBSCOKET_SERVER_URI);
    }
    if(socket) {
      socket.onopen = function () {
        console.log("interval clear ... ");
        clearInterval(intervalId);
        console.log("socket connected ... ");
        socket?.send(token);
      }
      socket.onclose = function (e) {
        console.log("disconnect ... ");
        console.log(e);
        if (e.wasClean) {
          console.log(`[close] 컨넥션 정상 종료(code=${e.code}) reason=${e.reason}`)
        } else {
          // 프로세스가 죽거나 네트워크 장애가 발생한 경우
          // e.code는 1006이 됨
          console.log(`[close] 컨넥션 비정상 종료(code=${e.code}) reason=${e.reason}`)
          setTimeout(()=>setDetect(!detect), 3000);
        }
      }
      socket.onerror = function (error) {
        console.log("Connection Error");
        console.error(error);
        socket?.close();
        
      }
      socket.onmessage = function (evt) {
        const data = JSON.parse(evt.data);
        console.log("message ... ");
        console.log(data);
        dispatch(setExaTokenValue(data));
      }
    }
  };



  useEffect(()=> {
    // console.log("현재 페이지: ", currentPage);
  },[currentPage])

  // 지갑 열고닫기 handler
  const onWalletSetting = (diff: boolean) => {
    dispatch(connectWalletSetting(diff));
  };

  /**
   * 뒤로 가기를 눌렀을 때 다시 terms and conditions 페이지로 이동하기 위한 이벤트 핸들러
   * 
   * @author jslee
   * @since 2022-10-19
   */
  const backHandler = () => {
    dispatch(setWalletPage({page: prevPage, prevPage:''}));
  }

  const pageHandler = () => {
    switch (currentPage) {
      case 'start':
        return (<WalletStart />)
      case 'login':
        return (<WalletLogin />)
      case 'terms':
        return (<WalletTerms />)
      case 'condition':
        return (<WalletCondition headerTitle={t('wallet_first_condition')} back backHandler={backHandler}/>)
      case 'policy':
        return (<WalletPolicy headerTitle={t('wallet_second_condition')} back backHandler={backHandler}/>)
      case 'password':
        return (<WalletPassword />)
      case 'change':
        return (<WalletChangePassword />)
      case 'menu':
        return (<WalletMenu headerTitle={t('wallet_menu_title')} menu />)
      case 'myAccount':
        return (<WalletMyAccount  headerTitle={t('wallet_menu_subtitle1')} back />)
      case 'connected':
          return (<WalletConnected headerTitle={t('wallet_menu_subtitle2')} back backHandler={backHandler}/>)
      case 'main':
        return (<WalletMain headerTitle={t('wallet_menu_title')} menu />)
      case 'send':
        return (<WalletSend headerTitle='Send' back />)
      case 'sendResult':
        return (<WalletSendResult headerTitle='Send Result' normal/>)
      case 'receive': 
        return (<WalletReceive headerTitle='Receive' back />)
      case 'nft':
        return (<WalletNFT headerTitle='NFT' back  backHandler={backHandler}/>)
      case 'tokens':
        return (<WalletTokens headerTitle='Tokens' back  backHandler={backHandler}/>)
      default:
        break;
    }
  }
  return (
    <div 
      className='dim'
      onClick={()=> {
        onWalletSetting(false);
      }}
    >
      <div 
        className='x_button'
        onClick={()=> {
          onWalletSetting(false);
        }}
      ></div>
      {pageHandler()}
    </div>
  );
}

export default Wallet;