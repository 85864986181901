import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { WalletHeader } from '../../../components'; 

import { WalletProps } from '../index'; 

/**
 * terms and Conditions에서 privacy Policy에 대한 detail을 보는 페이지
 * 
 * @author jslee
 * @since 2022-10-19
 */
const WalletPolicy = ({headerTitle, normal, back, menu, backHandler} : WalletProps) => {
  const { t }:{t: any} = useTranslation();

  return (
    <div 
      className={classNames(`wallet_box`, `conditions`, {wallet_header : headerTitle})}
      onClick={(e) => {
        e.stopPropagation();  
      }}
    >

      <WalletHeader headerTitle={headerTitle} back={back} backHandler={backHandler} />

      <div className='wallet-condition-body'>

        <p className="terms_des">{t('pp_content1')}</p>

        {/* 1조 */}
        <p className="terms_title">{t('pp_content2_title')}</p>
        <p className="terms_des">{t('pp_content2_description1')}</p>
        <p className="terms_des_num">
          {t('pp_content2_description2')}
          <span className="depth1">{t('pp_content2_description2_1')}</span>
        </p>
        <p className="terms_des_num">
          {t('pp_content2_description3')}
          <span className="depth1">{t('pp_content2_description3_1')}</span>
        </p>

        {/* 2조 */}
        <p className="terms_title">{t('pp_content3_title')}</p>
        <p className="terms_des_num">{t('pp_content3_description1')}</p>
        <p className="terms_des_num">
          {t('pp_content3_description2')}
          <span className="depth1">{t('pp_content3_description2_1')}</span>
          <span className="depth2">{t('pp_content3_description2_1_1')}</span>
          <span className="depth3">{t('pp_content3_description2_1_1_1')}</span>
          <span className="depth4">{t('pp_content3_description2_1_1_1_1')}</span>
          <span className="depth3">{t('pp_content3_description2_1_1_2')}</span>
          <span className="depth4">{t('pp_content3_description2_1_1_2_1')}</span>
        </p>

        {/* 3조 */}
        <p className="terms_title">{t('pp_content4_title')}</p>
        <p className="terms_des">{t('pp_content4_description1')}</p>
        <p className="terms_des_num">{t('pp_content4_description2')}</p>
        <p className="terms_des_num">{t('pp_content4_description3')}</p>
        <p className="terms_des_num">{t('pp_content4_description4')}</p>
        <p className="terms_des_num">{t('pp_content4_description5')}</p>

        {/* 4조 */}
        <p className="terms_title">{t('pp_content5_title')}</p>
        <p className="terms_des">{t('pp_content5_description1')}</p>
        <p className="terms_des_num">
          {t('pp_content5_description2_1')}
          <span className="depth1">{t('pp_content5_table1_description2_1')}</span>
          <span className='depth2'>{t('pp_content5_table1_description2_2')}</span>
        </p>
        <p className="terms_des_num">{t('pp_content5_description2_2')}</p>
        <p className="terms_des_num">{t('pp_content5_description2_3')}</p>
        <p className="terms_des_num">
          {t('pp_content5_description2_4')}
          <span className="depth1">{t('pp_content5_table2_description1_1')}</span>
          <span className='depth2'>
            {t('pp_content5_table2_title2')} : {t('pp_content5_table2_description1_2')}<br />
            {t('pp_content5_table2_title3')} : {t('pp_content5_table2_description1_3')}<br />
            {t('pp_content5_table2_title4')} : {t('pp_content5_table2_description1_4')}<br />
            {t('pp_content5_table2_title5')} : {t('pp_content5_table2_description1_5')}<br />
            {t('pp_content5_table2_title6')} : {t('pp_content5_table2_description1_6')}
          </span>
          <span className="depth1">{t('pp_content5_table2_description2_1')}</span>
          <span className='depth2'>
            {t('pp_content5_table2_title2')} : {t('pp_content5_table2_description2_2')}<br />
            {t('pp_content5_table2_title3')} : {t('pp_content5_table2_description2_3')}<br />
            {t('pp_content5_table2_title4')} : {t('pp_content5_table2_description2_4')}<br />
            {t('pp_content5_table2_title5')} : {t('pp_content5_table2_description2_5')}<br />
            {t('pp_content5_table2_title6')} : {t('pp_content5_table2_description2_6')}
          </span>
          <span className="depth1">{t('pp_content5_table2_description3_1')}</span>
          <span className='depth2'>
            {t('pp_content5_table2_title2')} : {t('pp_content5_table2_description3_2')}<br />
            {t('pp_content5_table2_title3')} : {t('pp_content5_table2_description3_3')}<br />
            {t('pp_content5_table2_title4')} : {t('pp_content5_table2_description3_4')}<br />
            {t('pp_content5_table2_title5')} : {t('pp_content5_table2_description3_5')}<br />
            {t('pp_content5_table2_title6')} : {t('pp_content5_table2_description3_6')}
          </span>
        </p>

        {/* 5조 */}
        <p className="terms_title">{t('pp_content6_title')}</p>
        <p className="terms_des_num">
          {t('pp_content6_description1')}
          <span className="depth1">{t('pp_content6_description1_1')}</span>
        </p>
        <p className="terms_des_num">{t('pp_content6_description2')}</p>
        <p className="terms_des_num">{t('pp_content6_description3')}</p>
        <p className="terms_des_num">{t('pp_content6_description4')}</p>
        <p className="terms_des_num">{t('pp_content6_description5')}</p>
        <p className="terms_des_num">{t('pp_content6_description6')}</p>

        {/* 6조 new */}
        <p className="terms_title">{t('pp_content7_title')}</p>
        <p className="terms_des">{t('pp_content7_description')}</p>
        {/* 1 */}
        <p className="terms_des_num"> 
          {t('pp_content7_table1_description1_1')}
        <span className="depth1">{t(`pp_content7_table1_title1`)}: {t('pp_content7_table1_description1_2')}</span>
        </p>
        {/* 2 */}
        <p className="terms_des_num"> 
          {t('pp_content7_table1_description2_1')}
        <span className="depth1">{t(`pp_content7_table1_title1`)}: {t('pp_content7_table1_description2_2')}</span>
        </p>
        {/* 3 */}
        <p className="terms_des_num"> 
          {t('pp_content7_table1_description3_1')}
        <span className="depth1">{t('pp_content7_table1_description3_2')}</span>
        </p>

        {/* 4 */}
        <p className="terms_des_num"> 
          {t('pp_content7_table1_description4_1')}
        <span className="depth1">{t('pp_content7_table1_description4_2')}</span>
        </p>

        {/* 5 */}
        <p className="terms_des_num"> 
          {t('pp_content7_table1_description5_1')}
        <span className="depth1">{t('pp_content7_table1_description5_2')}</span>
        <span className="depth2">{t(`pp_content7_table1_title1`)}: {t('pp_content7_table1_description5_2_1')}</span>
        <span className="depth1">{t('pp_content7_table1_description5_3')}</span>
        <span className="depth2">{t(`pp_content7_table1_title1`)}: {t('pp_content7_table1_description5_3_1')}</span>
        </p>

        {/* 6 */}
        <p className="terms_des_num">{t('pp_content7_table1_description6_1')}</p>

        {/* 7조 */}
        <p className="terms_title">{t('pp_content8_title')}</p>
        <p className="terms_des_num">{t('pp_content8_description1')}</p>
        <p className="terms_des_num">{t('pp_content8_description2')}</p>
        <p className="terms_des_num">
          {t('pp_content8_description3')}
          <span className="depth1">{t('pp_content8_description3_1')}</span>
          <span className="depth2">{t('pp_content8_table_description1')}</span>
        </p>

        {/* 8조 */}
        <p className="terms_title">{t('pp_content9_title')}</p>
        <p className="terms_des">{t('pp_content9_description1')}</p>
        <p className="terms_des_num">
          {t('pp_content9_description2')}
          <span className="depth1">{t('pp_content9_description2_1')}</span>
        </p>
        <p className="terms_des_num">
          {t('pp_content9_description3')}
          <span className="depth1">{t('pp_content9_description3_1')}</span>
        </p>
        <p className="terms_des_num">
          {t('pp_content9_description4')}
          <span className="depth1">{t('pp_content9_description4_1')}</span>
        </p>

        {/* 9조 */}
        <p className="terms_title">{t('pp_content10_title')}</p>
        <p className="terms_des_num">{t('pp_content10_description1')}</p>
        <p className="terms_des_num">
          {t('pp_content10_description2')}
          <span className="depth1">{t('pp_content10_description2_1')}</span>
        </p>
        <p className="terms_des_num">{t('pp_content10_description3')}
          {/* 반드시 필요한 쿠키 */}
          <span className="depth1">{t('pp_content10_description3_1')}</span>
          <span className="depth2">{t('pp_content10_description3_1_1')}</span>
          <span className="depth2">{t('pp_content10_description3_1_2')}</span>
          <span className="depth2">{t('pp_content10_description3_1_3')}</span>
          <span className="depth2">{t('pp_content10_description3_1_4')}</span>
          {/* 수행 쿠키 */}
          <span className="depth1">{t('pp_content10_description3_2')}</span>
          <span className="depth2">{t('pp_content10_description3_2_1')}</span>
          <span className="depth2">{t('pp_content10_description3_2_2')}</span>
          <span className="depth2">{t('pp_content10_description3_2_3')}</span>
          <span className="depth2">{t('pp_content10_description3_2_4')}</span>
          <span className="depth2">{t('pp_content10_description3_2_5')}</span>
          <span className="depth2">{t('pp_content10_description3_2_6')}</span>

          {/* 기능 쿠키 */}
          <span className="depth1">{t('pp_content10_description3_3')}</span>
          <span className="depth2">{t('pp_content10_description3_3_1')}</span>
          <span className="depth2">{t('pp_content10_description3_3_2')}</span>
          <span className="depth2">{t('pp_content10_description3_3_3')}</span>

          {/* 대상 쿠키 */}
          <span className="depth1">{t('pp_content10_description3_4')}</span>
          <span className="depth2">{t('pp_content10_description3_4_1')}</span>
          <span className="depth2">{t('pp_content10_description3_4_2')}</span>
          <span className="depth2">{t('pp_content10_description3_4_3')}</span>

          {/* 대상 쿠키 */}
          <span className="depth1">{t('pp_content10_description3_5')}</span>
          <span className="depth2">{t('pp_content10_description3_5_1')}</span>
          <span className="depth2">{t('pp_content10_description3_5_2')}</span>
          <span className="depth2">{t('pp_content10_description3_5_3')}</span>
        </p>

        {/* 10조 */}
        <p className="terms_title">{t('pp_content11_title')}</p>
        <p className="terms_des">{t('pp_content11_description1')}</p>

        {/* 11조 */}
        <p className="terms_title">{t('pp_content12_title')}</p>
        <p className="terms_des_num">
          {t('pp_content12_description1')}
          <span className="depth1">{t('pp_content12_description2')}</span>
          <span className="depth2">{t('pp_content12_description2_1')}</span>
        </p>
        <p className="terms_des_num">
          {t('pp_content12_description3')}
          <span className="depth1">{t('pp_content12_description4')}</span>
          <span className="depth2">{t('pp_content12_description4_1')}</span>
        </p>

        {/* 12조 */}
        <p className="terms_title">{t('pp_content13_title')}</p>
        <p className="terms_des">
          {t('pp_content13_description1')}
          <span className="depth1">{t('pp_content13_description1_1')}</span>
        </p>

        {/* 13조 */}
        <p className="terms_title">{t('pp_content14_title')}</p>
        <p className="terms_des">
          {t('pp_content14_description1')}
        </p>

      </div>
    </div>
  );
};

export default WalletPolicy;