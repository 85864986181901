import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

interface MetaProps {
  metaTagNameTitle?: string;
  metaDescription?: string;
  url?: string;
}

const MetaTag = ({metaTagNameTitle, metaDescription, url}: MetaProps) => {
  const { t }:{ t:any } = useTranslation();

  return (
    <Helmet>
      <title>{metaTagNameTitle ? metaTagNameTitle : t('meta_title')}</title>
        <meta name="title" content={metaTagNameTitle ? metaTagNameTitle : t('meta_title')} />
        <meta name="keywords" content={t('meta_tag')} />
        <meta name="description" content={metaDescription ? metaDescription : t('meta_description')} />
        {/* facebook */}
        <meta property="og:title" content={metaTagNameTitle ? metaTagNameTitle : t('meta_title')} />
        <meta property="og:description" content={metaDescription ? metaDescription : t('meta_description')} />
        <meta property="og:image" content="https://zemi.world/meta_images.jpg" />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="800" />
        <meta property="og:image:height" content="600" />
        <meta property="og:site_name" content="Zemi World" /> 
        <meta property="og:type" content="Website"/>
        <meta property="og:url" content="https://zemi.world"/>
        {/* tweeter */}
        <meta name="twitter:title" content={metaTagNameTitle ? metaTagNameTitle : t('meta_title')} />
        <meta name="twitter:description" content={metaDescription ? metaDescription : t('meta_description')} />
        <meta name="twitter:image" content="https://zemi.world/meta_images.jpg" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:url" content="https://twitter.com/EXAIS_1018" />
        <meta name="twitter:site" content="@EXAIS_1018" />
        <meta name="twitter:creator" content="@EXAIS_1018" />
    </Helmet>
  )
};

export default MetaTag;