import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import langEn from './lang_packs/en.json';
import langKo from './lang_packs/ko.json';

const resources = {
  en: { translation: langEn },
  ko: { translation: langKo },
}

// const userLanguage = window.navigator.language;
const userLanguage = sessionStorage.getItem('language') as string;
i18n.use(initReactI18next).init({
  resources,
  lng: userLanguage,
  fallbackLng: 'en',
  keySeparator: false,
  interpolation: {
    escapeValue: false
  }
})

export default i18n;

export const languages = [ 'en', 'ko-KR' ] as const;
export type Languages = typeof languages[number];