import { combineReducers } from 'redux';

import { persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage'; // 로컬 스토리지
import storageSession from 'redux-persist/lib/storage/session'; // 세션 스토리지

import routes from './routes';
import login from './login';
import modal from './modal';
import wallet from './wallet';

const persistConfig = {
  key: "root",
  storage: storageSession,
  whitelist: ['login', 'modal', 'wallet']
};

const rootReducer = combineReducers({
  routes,
  login,
  modal,
  wallet
});

// export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
export default persistReducer(persistConfig, rootReducer);
export * from './login';
export * from './modal';
export * from './routes';
export * from './wallet';