import {useEffect} from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import { GoogleLogin } from 'react-google-login';
import {gapi} from 'gapi-script';

import { useDispatch } from 'react-redux';

import { saveSnsToken, setWalletPage } from '../../../store';
import { encrypto, setLoginData, AUTH_SERVER_URI, CLIENT_ID, REDIRECT_URI } from './../../../utils';

import './walletSnsButton.scss';
/**
 * google login button component
 * TODO google api client_id 수정
 * 
 * @returns google button tsx
 * 
 * @author jslee
 * @since 2022-10-18
 */
function GoogleLoginButton() {
  const { t }: { t: any } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: CLIENT_ID,
        scope: 'email',
      });
    }

    gapi.load('client:auth2', start);
  }, []);

  /**
   * 로그인 성공시 flow
   * 
   * @param response 성공한 로그인 정보
   */
  const _successHandler = (response: any) => {
    // console.log(response);
    const date = new Date();

    let e_data = encrypto(date.getTime(), response.accessToken); //로그인 성공 후 받은 token을 암호화 

    // 암호화한 데이터를 전송
    axios.post(AUTH_SERVER_URI, {
      category: 'google',
      cryption_code: e_data,
      datetime: date
    })
    .then((res: any) => {
      // console.log("Res", res);
      if(res.data.response === 200 && res.data.access_token) { // 로그인 한 유저가 지갑이 있을 경우
        setLoginData('main', dispatch, res, response.profileObj.email, 'google');
      } else { // 로그인한 유저가 지갑이 없는 경우
        if(res.data.response === 404) {
          dispatch(saveSnsToken({token: response.accessToken, category: 'google', email: response.profileObj.email}));
          dispatch(setWalletPage({page: 'terms', prevPage: ''}));
        } else if(res.data.response === 500){
          alert("탈퇴한 계정 입니다.");
          dispatch(setWalletPage({page: 'start', prevPage: ''}));
        }
      }
    })
    .catch(error => console.error(error));
  }

  /**
   * 구글 로그인이 실패했을 경우
   * 
   * @param response google 로그인을 실패한 데이터
   */
  const _failHandler = (response: any) => {
    console.error(response);
  }




  return (
    <GoogleLogin
      clientId={CLIENT_ID}
      redirectUri={REDIRECT_URI}
      onSuccess={_successHandler}
      onFailure={_failHandler}
      cookiePolicy={'single_host_origin'}
      render={(prev) => {
        return (
        <div
          className="wallet-login-button google"
          onClick={prev.onClick}
        >
          <div className='icon'></div>
          <p>{t(`wallet_login_google`)}</p>
        </div>)
      }}
    >

    </GoogleLogin>
  );
};

export default GoogleLoginButton;