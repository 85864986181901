import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { WalletHeader, WalletMainButton } from '../../../components';
import { WalletProps } from './../index';
import { TokenList, NftList, HistoryList, NoData } from '../../../components';


import { useDispatch, useSelector } from 'react-redux';
import { hnadleWalletSubmenu } from './../../../store/wallet';
import './walletMain.scss';

const WalletMain = ({ headerTitle, normal, back, menu }: WalletProps) => {

  const { t }: { t: any } = useTranslation();
  const dispatch = useDispatch();

  const nowOpen = useSelector((state: any) => state.wallet.nowOpen);
  const exaToken = useSelector((state: any) => state.wallet.exaToken);
  const address = useSelector((state:any) => state.login.address);
  useEffect(() => {
    dispatch(hnadleWalletSubmenu(t('wallet_token_subtitle1')));
    // console.log("전달 받은 주소는?", address);
  }, [])
  const [isClip, setIsClip] = useState(false);

  /**
   * 하단 메뉴
   * 현재 Token/NFT/History
   */
  const [walletMainMenu, setWalletMainMenu] = useState([
    { id: 1, title: t('wallet_token_subtitle1'), url: `url('../../assets/images/wallet/wallet_main_button1)` },
    { id: 2, title: t('wallet_token_subtitle2'), url: `url('../../assets/images/wallet/wallet_main_button2)` },
    { id: 3, title: t('wallet_token_subtitle3'), url: `url('../../assets/images/wallet/wallet_main_button3)` },
  ]);

  /**
   * 지갑 메인 페이지 하단 메뉴 map -> 현재 Token/NFT/History
   */
  const walletMainMenuMap = walletMainMenu.map((data: any) => (
    <li
      className={classNames({ on: nowOpen === data.title })}
      onClick={() => {
        // setNowOpen(data.title);
        dispatch(hnadleWalletSubmenu(data.title));
      }}
    >
      {data.title}
    </li>
  ));

  /**
  * 하단 Token/NFT/History 중 현재 열린 페이지
  *  추 후 실제 데이터가 들어오면 업데이트 필요
  * 
  * @author jslee
  * @since 2022-10-24
  */
  const subMenuHandler = () => {
    if (nowOpen === t('wallet_token_subtitle1')) {
      // return <TokenList token={'EXA'} memo={'example'} value={100.385474} />
      return <NoData />;
    } else if (nowOpen === t('wallet_token_subtitle2')) {
      // return <NftList type={1} title={'NFT Item Title'} url={'https://via.placeholder.com/300.jpg'} />
      return <NoData />;
    } else if (nowOpen === t('wallet_token_subtitle3')) {
      // return <HistoryList type={0} date={'2022-12-01 12:30:28(UTC)'} tx={"hey"} to={"hey"} amount={3.489564} from={"hey"} />
      return <NoData />;
    } else {
      return <NoData />;
    }
  }

  const clipClick = async() => {
    try {
      await navigator.clipboard.writeText(address.origin);
      setIsClip(true);
      setTimeout(()=>{setIsClip(false)}, 2000);
    } catch (e) {
      console.error('복사에 실패하였습니다');
    }
    
  }


  return (
    <div
      className={classNames(`wallet_box`, `main`, { wallet_header: headerTitle })}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >

      {headerTitle && <WalletHeader headerTitle={headerTitle} normal={normal} back={back} menu={menu} />}

      <div className="main_container">
        <div className="content1">
          <div className="content1_inner">
            <div 
            className={classNames("copy_box", {'copied': isClip})}
            onClick={clipClick}
            >{/* 클립보드에 복사 시 copied 클래스 추가 */}
              {isClip ? <p>Copied!</p> : <p>{address.trans}</p>}{/* 클립보드에 복사 시 주소에서 Copied!로 문구 교체 */}
            </div>
            <p className="title">{t('wallet_token_total_balance')}</p>
            <div className="content1_inner_box">
              <p className="total">{exaToken}</p>
              <div className="token">
                <p>{t('wallet_token_name')}</p>
              </div>
            </div>
            {/* <p className="usd">= 0.00 USD</p> */}
          </div>
          <div className="content1_inner">
            <WalletMainButton title={t('wallet_token_send')} />
            <WalletMainButton title={t('wallet_token_receive')} />
            <WalletMainButton title={t('wallet_token_convert')} />
          </div>
        </div>
        <div className="content2">
          <ul className="wallet_top_menu">
            {walletMainMenuMap}
          </ul>
        </div>
        <div className={classNames(`content2_box`, { token: nowOpen === t('wallet_token_subtitle1') }, { nft: nowOpen === t('wallet_token_subtitle2') }, { history: nowOpen === t('wallet_token_subtitle3') })}>
          {subMenuHandler()}
        </div>
      </div>
    </div>
  );
}

export default WalletMain;