import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { RootState, connectWalletSetting, connectRouteSetting, connectModalSetting } from '../../store';

import { AlertModal } from '../Modal';

import { HeaderProps } from './header.types';
import { Wallet } from '../../pages';

const Header = ({ title, location }: HeaderProps) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();

  // 지갑 open 여부
  const walletOpen = useSelector((state: RootState) => state.wallet.open);

  // modal open 여부
  const modalOpen = useSelector((state: RootState) => state.modal.open);

  const [gnbOpen, setGnbOpen] = useState(false);

  const navMap = title.map((data: any) => (
    <li
      className={location === data.link ? "on" : ""}
      key={data.id}
    >
      <Link
        onClick={(e) => {
          if(
            data.title === t("header2") 
            || data.title === t("header3") 
            || data.title === t("header4")
            ) {
            e.preventDefault();
            dispatch(connectModalSetting(!modalOpen));
          } else {
            window.scrollTo(0, 0);
            dispatch(connectRouteSetting(data.title));
            setGnbOpen(false);
          }
        }}
        to={data.link}
      >
        <div className="image"></div>
        {data.title}
      </Link>
    </li>
  ));

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    if (gnbOpen || modalOpen || walletOpen) {
      body.classList.add('scrollLock');
    } else {
      body.classList.remove('scrollLock');
    }
  }, [gnbOpen, modalOpen, walletOpen]);

  return (
    <header>
      
      <div className="header_box">
        {/* logo */}
        <Link
          className="logo"
          onClick={(e) => {
              dispatch(connectRouteSetting(t("header1")));
              setGnbOpen(false);
          }}
          to={"/"}
        ></Link>

        {/* gnb */}
        <div className={gnbOpen ? "gnb on" : "gnb"}>
          {gnbOpen ? (
            <div className='blur_box'
            onClick={() => {
              setGnbOpen(!gnbOpen);
            }}
            ></div>
          ) : null}
          <div className={gnbOpen ? "gnb_mobile_button on" : "gnb_mobile_button"}
            onClick={() => {
              setGnbOpen(!gnbOpen);
            }}
          >
            <div className="gnb_mobile_button_bar"></div>
          </div>

          <ul className={gnbOpen ? "gnb_box on" : "gnb_box"}>
            {navMap}
          </ul>
        </div>
      </div>

      {/* wallet button */}
      <div
        className="wallet"
        onClick={(e) => {
          // e.preventDefault();
          // onModalSetting(!modalOpen);
          dispatch(connectWalletSetting(!walletOpen));
        }}
      >
        <div className="image"></div>
        <p>wallet</p>
      </div>
      { walletOpen ? <Wallet/> : null }
      { modalOpen ? <AlertModal description={t('modal_alert_waiting')} confirm={t('modal_alert_waiting_confirm')} /> : null }
    </header>
  );
}

export default Header;