import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { WalletHeader, ServiceButton } from '../../../components';
import { WalletProps } from '../index'; 
import './walletConnected.scss';

/**
 * Wallet Connected
 * * 아직 아무것도 표시 안함, 기본틀만 구현한 상태
 * TODO 기능 구현 필요
 * 
 * @author jslee
 * @since 2022-10-31
 */
const WalletConnected = ({headerTitle, normal, back, menu, backHandler} : WalletProps) => {

  const { t } = useTranslation();

  const handleButton = () => {
    // 아직 기능 없음
  }

  return (
    <div 
      className={classNames(`wallet_box`, `connected`, {wallet_header : headerTitle})}
      onClick={(e) => {
        e.stopPropagation();  
      }}
    >

      {headerTitle && <WalletHeader headerTitle={headerTitle} normal={normal} back={back} menu={menu} backHandler={backHandler}/>}

      <div className="conneted_box">
        <ServiceButton title={t('connected_service_title1')} button={t('connected_service_button')} handleButton={handleButton}/>
      </div>

    </div>
  );
}

export default WalletConnected;