import classNames from 'classnames';
import axios from 'axios';
import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { encrypto, REGISTE_SERVER_URI, REPASSWORD_SERVER_URI, setLoginData } from '../../../utils'; 
import { RootState, deleteSnsToken, LoginState, resetChangePassword, initWallet, handleWalletMenuOpen, setWalletPage } from '../../../store';

import './walletPassword.scss';
import { WalletHeader } from '../../../components';

/**
 * 지갑의 비밀번호에 관한 페이지 
 * 
 * TODO change password에 대한 처리를 어떻게 할지 flow 및 구현 필요
 * TODO 추후 비밀번호 입력에 관한 flow 및 기능 구현 필요
 * 
 * @author jslee
 * @since 2022-10-28
 */
const WalletPassword = () => {

  const { t }: {t: any} = useTranslation();
  const dispatch = useDispatch();

  const [status, setStatus] = useState('first');
  const [warnning, setWarnning] = useState(false);

  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');

  const login: LoginState = useSelector((state: RootState) => state.login);

  /* 첫번째 비밀번호를 모두 입력하면 두번째 비밀번호로 이동하기 위한 hook  */
  useEffect(() => {
    if (password.length === 6) {
      setWarnning(false);
      setStatus('second');
    }
  }, [password]);

  /* 두번째 비밀번호를 모두 입력하면 첫번째 입력 비밀번호와 두번째 입력 비밀번호가 일치 하는지 check 하는 메서드  */
  useEffect(() => {
    if (rePassword.length === 6) { //입력 비밀번호 일치
      if (password === rePassword) {
        login.isRepwd ? _changePassword() : register();// 등록을 수행하는 메서드로 이동
        
      } else { // 입력 비밀번호 불일치, 모두 초기화 하고 첫번째 입력으로 이동
        // alert("잘못 입력");
        setWarnning(true);
        setStatus('first');
        setPassword('');
        setRePassword('');
      }
    }
  }, [rePassword]);

  /**
   * password에 로그인한 계정과 비밀번호를 암호화해서 전송
   * 
   * @since 2022-10-28
   * @author jslee
   */
  const register = () => {
    const date = new Date();
    let e_token = encrypto(date.getTime(), login.sns_token as string);
    let e_password = encrypto(date.getTime(), rePassword);

    axios.post(REGISTE_SERVER_URI, {
      category: login.category, // google, facebook, apple 로그인 플랫폼
      cryption_code: e_token, // 암호화된 로그인 access_token
      password: e_password, //암호화된 password
      datetime: date // 시간
    })
    .then((res: any) => {
      // console.log("res", res);
      if(res.data.response === "ok" && res.data.access_token) { // 로그인한 유저의 지갑 생성 완료
        dispatch(deleteSnsToken()); // 유지 중이던 snsToken을 초기화( 우리 서버에서 보내준 토큰만 관리하면 됨 )
        setLoginData('main', dispatch, res, login.email, login.category); // 로그인한 정보 redux에 저장
      } else { // 로그인한 유저의 지갑 생성 실패
        alert("지갑 생성 실패, 관리자에게 문의 하세요");
      }
    })
    .catch(error => console.error(error));
  }

  const _changePassword = () => {
    const date = new Date();
    let e_password = encrypto(date.getTime(), login.inputPassword as string);
    let e_repassword = encrypto(date.getTime(), rePassword);

    axios.post(REPASSWORD_SERVER_URI, {
      accestoken: login.token,
      password: e_password,
      repassword: e_repassword,
      datetime: date
    })
    .then((res: any) => {
      if(res.data.response === "ok" && res.data.access_token) {
        dispatch(resetChangePassword());
        dispatch(handleWalletMenuOpen(false));
        setLoginData('main', dispatch, res, login.email, login.category); 
      }
    })
    .catch(error=>console.error(error));
  }

  /**
   * password 입력 버튼(pin 버튼)
   * 
   * @param index 
   * @returns 로그인 할 때 보이는 숫자패드 버튼
   * 
   * @author jslee
   * @since 2022-10-28
   */
  const contentHandler = (index: number) => {
    let key = index as number + 1;
    return (
      <div
        className={`number_pad_${key}`}
        onClick={() => {
          if (key === 10) {
            if (status === 'first') {
              setPassword('');
            } else {
              setRePassword('');
            }
          } else if (key === 11) {
            if (status === 'first') {
              setPassword(password.concat('0'));
            } else {
              setRePassword(rePassword.concat('0'));
            }
          } else if (key === 12) {
            if (status === 'first') {
              setPassword(password.slice(0, -1));
            } else {
              setRePassword(rePassword.slice(0, -1));
            }
          } else {
            if (status === 'first') {
              setPassword(password.concat(String(key)));
            } else {
              setRePassword(rePassword.concat(String(key)));
            }
          }
      }}>
        {key === 10 || key === 12 ? '' : key === 11 ? '0' : key}
      </div>
    );
  }

  /**
   * 상단 password *****로 보여주는 부분
   * 
   * @param index 몇 번째 점인지 index(0부터 시작해서 key는 + 1시켜준다.)
   * @returns 로그인할 때 상단의 dots
   * 
   * @author jslee
   * @since 2022-10-28
   */
  const dotHandler = (index: number) => {
    let key = index as number + 1;
    if (status === 'first') {
      return (
        <div className={classNames('dot', {on : password.length >= key})}></div>
      );
    } else {
      return (
        <div className={classNames('dot', {on : rePassword.length >= key})}></div>
      );
    }
  }

  const backHandler = () =>{
    dispatch(setWalletPage({page:'myAccount', prevPage:'menu'}));
  }

  return(
    <div 
      className={classNames(`wallet_box`, `password`, {'password-header': login.isRepwd, "wallet_header": login.isRepwd})}
      onClick={(e) => {
        e.stopPropagation();  
      }}
    >
      {login.isRepwd ? <WalletHeader back={true} backHandler={backHandler}/> : null}
      <div className='content1'>
        <p className="title">{login.isRepwd ? t('new_password2_title') : t('new_password_title')}</p>
        <p className={classNames(`description`, {"warnning": warnning})}>
          { login.isRepwd ? status === 'first' ? warnning ? t('new_password2_description3') : t('new_password2_description1') : t('new_password2_description2') : status === 'first' ? warnning ? t('new_password_description3') : t('new_password_description1') : t('new_password_description2') }
        </p>
        <div className="password_dot">
          {[...Array(6)].map((element, index) => dotHandler(index))}
        </div>
      </div>
      <div className="number_pad">
          {[...Array(12)].map((element, index) => contentHandler(index))}
        </div>
    </div>
  );
}

export default WalletPassword;