import { WalletTermsCheckboxProps } from '../pages/Wallet/Wallet.types';

const WALLET_SETTING = 'wallet/WALLET_SETTING' as const; // 지갑 모달창을 열었는지 check
const WALLET_PAGE_SETTING = 'wallet/WALLET_PAGE_SETTING' as const; // 지갑 페이지 세팅(지갑 페이지 이동)
const WALLET_TERMS_CHECK = 'wallet/WALLET_TERMS_CHECK' as const; // 지갑 terms checkbox 관리
const WALLET_INIT = 'wallet/WALLET_INIT' as const; // 뒤로 가기 클릭시 동작

const WALLET_MENU_OPEN = 'wallet/WALLET_MENU_OPEN' as const; // wallet 내에서 menu 버튼을 클릭시 동작
const WALLET_MODAL_OPEN = 'wallet/WALLET_MODAL_OPEN' as const; // wallet 내에서 modal이 필요할 경우(logout, delete 등 2차 확인)
const WALLET_MAIN_SUBMENU_SET = 'wallet/WALLET_MAIN_SUBMENU_SET' as const; // main에서 하단 nft, history, token 버튼 클릭 시 set
const WALLET_EXA_TOKEN_SET = 'wallet/WALLET_EXA_TOKEN_SET' as const; // exa 토큰의 값을 변경
const WALLET_RESET_STATE = 'wallet/WALLET_RESET_STATE' as const;

type WalletAction =
  | ReturnType<typeof connectWalletSetting>
  | ReturnType<typeof setWalletPage>
  | ReturnType<typeof checkWalletTerms>
  | ReturnType<typeof initWallet>
  | ReturnType<typeof handleWalletMenuOpen>
  | ReturnType<typeof handleWalletModalOpen>
  | ReturnType<typeof hnadleWalletSubmenu>
  | ReturnType<typeof setExaTokenValue>
  | ReturnType<typeof resetWalletState>
;

export const connectWalletSetting = (diff: boolean) => ({
  type: WALLET_SETTING,
  payload: diff
});

export const setWalletPage = (diff: {page: string; prevPage: string}) => ({
  type: WALLET_PAGE_SETTING,
  payload: diff
});

export const checkWalletTerms = (diff: WalletTermsCheckboxProps) => ({
  type: WALLET_TERMS_CHECK,
  payload: diff
});

export const initWallet = (diff: boolean) => ({
  type: WALLET_INIT,
  payload: diff
})
export const handleWalletMenuOpen = (diff: boolean) => ({
  type: WALLET_MENU_OPEN,
  payload: diff
});

export const handleWalletModalOpen = (diff: boolean) => ({
  type: WALLET_MODAL_OPEN,
  payload: diff
});

export const hnadleWalletSubmenu = (diff: string) => ({
  type: WALLET_MAIN_SUBMENU_SET,
  payload: diff
})

export const setExaTokenValue = (diff: number) => ({
  type: WALLET_EXA_TOKEN_SET,
  payload: diff
})

export const resetWalletState = () => ({
  type: WALLET_RESET_STATE
})

export type WalletState = {
  open: boolean;
  page: string;
  history: [];
  next:[];
  prevPage: string;
  terms: WalletTermsCheckboxProps;
  walletMenuOpen: boolean;
  walletModalOpen: boolean;
  nowOpen: string;
  exaToken: number;
};

const initialState: WalletState = {
  open: false,
  page: 'start',
  prevPage: '',
  history: [],
  next:[],
  terms: { 
    term: false,
    policy: false,
    disabled: true,
  },
  walletMenuOpen: false,
  walletModalOpen: false,
  nowOpen: '',
  exaToken: 0
};

function wallet(
  state: WalletState = initialState,
  action: WalletAction
): WalletState {
  switch (action.type) {
    case WALLET_SETTING:
      return { ...state, open: action.payload };
    case WALLET_PAGE_SETTING: 
      return { ...state, page: action.payload.page, prevPage: action.payload.prevPage };
    case WALLET_TERMS_CHECK: 
    return { ...state, terms: action.payload };
    case WALLET_INIT: 
      state = initialState;
      if(action.payload) {
        return { ...state, page:'main'};
      } else {
        return { ...state }
      }     
    case WALLET_MENU_OPEN: 
      return { ...state, walletMenuOpen: action.payload };
    case WALLET_MODAL_OPEN: 
      return { ...state, walletModalOpen: action.payload };      
    case WALLET_MAIN_SUBMENU_SET: 
      return { ...state, nowOpen: action.payload };    
    case WALLET_EXA_TOKEN_SET: 
      return { ...state, exaToken: action.payload };  
    case WALLET_RESET_STATE:
        state = initialState;
      return { ...state, open: true };  
    default:
      return state;
  }
}

export default wallet;