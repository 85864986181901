import classNames from "classnames";
import { WalletHeader, WalletNormalButton } from "../../../components";
import { WalletProps } from "../index";
import './walletReceive.scss';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState, WalletState, LoginState } from "../../../store";
import QRCode from 'qrcode';

const WalletReceive = ({headerTitle, normal, back, menu, backHandler} : WalletProps) => {

  const login:LoginState = useSelector((state:RootState) => state.login);

  useEffect(()=> {
    const address = login.address?.origin as string;
    QRCode.toCanvas('text', {errorCorrectionLevel:'H',margin:0, width:160}, function (err, canvas) {
      if(err) throw err;

      let container = document.getElementById('qr_code');
      container?.appendChild(canvas);
    })
  },[]);

  const handleButton = () => {
    console.log("뒤로가기!!!");
  }
  return (
    <div 
      className={classNames(`wallet_box`, `receive`, `wallet_header`)}
      onClick={(e)=>e.stopPropagation()}
    >
      <WalletHeader headerTitle={headerTitle} back={back} backHandler={handleButton}/>
      <div className="receive_content">
        <div id="qr_code" className="qr_code"></div>
        <div className="receive_wrapper">
          <p className="title">Address</p>
          <div className="address"><p className="address_value">ndj93u84mjicmcnfdohnl30</p></div>

          <div className="list_data">
            <p>The above address is for <span className="highlight">EXA deposit only.</span></p>
          </div>
          <div className="list_data"><p>The transaction may take 30 minutes or more to reflect in the EXAIS wallet.</p></div>
        </div>
        <WalletNormalButton handleButton={handleButton} title='Copy Address'/>
      </div>
    </div>
  )
}

export default WalletReceive;