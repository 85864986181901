import classNames from "classnames";
import { WalletHeader, WalletNormalButton } from "../../../components";
import { WalletProps } from "../index";
import './walletSendResult.scss';
const WalletSendResult = ({ headerTitle, normal, back, menu, backHandler }: WalletProps) => {
  const handleButton = () => {
    console.log("메인으로 이동");
  }
  return (
    <div
      className={classNames(`wallet_box`, `send_result`, `wallet_header`)}
      onClick={(e) => e.stopPropagation()}
    >
      <WalletHeader headerTitle={headerTitle} normal={normal} />
      <div className="send_result_wrapper">
        <div className="send_result_content">
          <div className="result_content">
            <div className="result_title">Status</div>
            <div className="result_value">Pending</div>
          </div>
          <div className="result_content">
            <div className="result_title">Amount</div>
            <div className="result_value">10.25 <span>ZEMI</span></div>
          </div>
          <div className="result_content">
            <div className="result_title">Transaction Fee</div>
            <div className="result_value">0.5 <span>ZEMI</span></div>
          </div>
          <div className="result_content">
            <div className="result_title">Receive</div>
            <div className="result_value">10.25 <span>ZEMI</span></div>
          </div>
        </div>
        <WalletNormalButton handleButton={handleButton} title='Next'/>
      </div>
    </div>
  )
}
export default WalletSendResult;