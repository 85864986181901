import { loginSuccess } from "../store/login";
import { setWalletPage } from "../store/wallet";
import { AnyAction, Dispatch } from "redux";
import { useSelector } from 'react-redux';

  /**
   * 로그인한 데이터를 redux를 통해서 저장
   * 
   * @param res axios를 통해서 받은 exais 서버의 토큰
   */
  export const setLoginData = (redirect: string, dispatch:Dispatch<AnyAction>, res: any, email: string, category: string) => {
    // console.log("res", res);
    if(res.data.address) {
      const address = res.data.address;
      let trans_address = address.substring(0, 5) + "..." + address.substring(address.length - 4, address.length);
      dispatch(loginSuccess({token: res.data.access_token, exp: res.data.expires_in, email: email, category: category, address: {origin: res.data.address, trans: trans_address}}));
    } else {
      dispatch(loginSuccess({token: res.data.access_token, exp: res.data.expires_in, email: email, category: category}));
    }
    dispatch(setWalletPage({page: redirect, prevPage: ''}));
    
  } 