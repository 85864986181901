const Support = () => {
    return(
        <div className="support scroll">
            <p>Support</p>
            <p>Support</p>
            <p>Support</p>
            <p>Support</p>
            <p>Support</p>
        </div>
    );
}

export default Support;