import { useSelector, useDispatch } from 'react-redux';

import { RootState, connectModalSetting } from '../../../store';

import './modal.scss';

type AlertModalProps = {
  description: string;
  confirm?: string;
};

const AlertModal = ({ description, confirm }: AlertModalProps) => {

  const dispatch = useDispatch();

  // modal open 여부
  const modalOpen = useSelector((state: RootState) => state.modal.open);
  // modal 열고닫기 기능
  const onModalSetting = (diff: boolean) => {
    dispatch(connectModalSetting(diff));
  };

  return (
    <div 
      className="dim"
      onClick={() => {
        onModalSetting(!modalOpen);
      }}
    >
      <div className="modal alert"
        onClick={(e) => {
          e.stopPropagation();  
        }}
      >
        <div 
          className="close_button"
          onClick={() => {
            onModalSetting(!modalOpen);
          }}
        ></div>
        <div className="modal_inner">
          <div className="image"></div>
          <p className="description">{description}</p>
        </div>
        <div 
          className="modal_button"
          onClick={() => {
            onModalSetting(!modalOpen);
          }}
        >
          <p>{confirm}</p>
        </div>
      </div>
    </div>
  );
}

export default AlertModal;