
import { useDispatch } from 'react-redux';

import { WalletModalProps } from '../../../pages'; 
import { handleWalletModalOpen } from '../../../store'; 

import './walletModal.scss';

/**
 * 지갑 일반 모달(팝업)
 * @param description1 description이 1개일 때 여기에만 내용 추가
 * @param description2 description 사이에 highlight 내용이 있을 경우를 위한 param
 * @param highlight highlight 문구
 * @param cancel 취소 버튼 문구
 * @param confirm 컨펌 버튼 문구
 * @param closeModal 모달 종료 / 취소 버튼 클릭시 동작 함수
 * @param confirmModal 컨펌 버튼 클릭시 동작 함수
 */
const WalletModal = ({ description1, description2, highlight, cancel, confirm, closeModal, confirmModal } : WalletModalProps) => {

  return (
    <div className="wallet_modal_dim">
      <div className="wallet_modal_box">
        <div
          className="close_button"
          onClick={() => {
            closeModal(false);
          }}
        ></div>
        <p className="description">
          {description1}
          <span className="highlight">{highlight}</span>
          {description2}
        </p>
        <div className="bottom_buttons">
          <p
            className="button"
            onClick={() => {
              closeModal(false);
            }}
          >{cancel}</p>
          <p 
            className="button"
            onClick={() => {
              confirmModal();
            }}
          >{confirm}</p>
        </div>
      </div>
    </div>
  );
}

export default WalletModal;