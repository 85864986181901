import classNames from 'classnames';
import {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { WalletTermsCheckboxProps } from '../index';

import { WalletNormalButton } from '../../../components';
import { checkWalletTerms, setWalletPage } from '../../../store';
import './walletTerms.scss';

/**
 * Wallet Terms and Conditions Pages
 * Terms and Conditions의 메인 페이지
 * 
 * @author jslee
 * @since 2022-10-18
 */
function WalletTerms() {

  const { t }: { t: any } = useTranslation();
  const dispatch = useDispatch();

  // redux로 terms에 대한 값을 관리 
  const [check, setCheck] = useState<WalletTermsCheckboxProps>(useSelector((state: any) => state.wallet.terms)) 
  
  
  useEffect(()=>{
    if(check.term === true && check.policy === true) {
      setCheck({
        ...check,
        disabled: false });   
    } else {
      setCheck({
        ...check,
        disabled: true });
    }
    dispatch(checkWalletTerms(check));
  }, [check.term, check.policy]);

  /**
   * next 버튼을 클릭했을 때 동작하게 될 메서드 
   * 
   * @author jslee
   * @since 2022-10-28
   */
  const handleButton = () => {
    dispatch(setWalletPage({page:'password', prevPage:''}));
  }

  /**
   * check box를 클릭했을 때 동작하게 될 checkbox handler 
   * @param text terms or policy
   * 
   * @author jslee
   * @since 2022-10-28
   */
  const checkHandler = async(text: string) => {
    setCheck({
      ...check,
      [text]: !check[text]
    });
  } 

  /**
   * coditions에 관한 디테일한 내용 또는 policy에 관한 디테일한 내용을 클릭했을 때 동작하게 되는 
   * 핸들러
   * 
   * @param text 현재 페이지
   * 
   * @author jslee
   * @since 2022-10-28
   */
  const clickDetail = (text: string) => {
    dispatch(setWalletPage({page: text, prevPage:'terms'}));
  }

  return (
    <div 
      className={classNames(`wallet_box`, 'terms')}
      onClick={(e) => {
        e.stopPropagation();  
      }}
    >
      <div className='terms-header'>
        <div className='terms-title'>
          {t(`wallet_conditions_title`)}
        </div>
      </div>
      <div className="wallet-terms-body">
        <div className="warning_box">
          <p className="warning_message_title">{t('terms_warning_title')}</p>
          <p className="warning_message_description">{t('terms_warning_description1')}</p>
          <p className="warning_message_description">{t('terms_warning_description2')}</p>
        </div>
        <div className='wallet-checkbox'>
          <div className='check-items'>
            <div 
              className={classNames('checkbox', {'check': check.term})} 
              onClick={()=>checkHandler('term')}
            ></div>
            <p onClick={()=>clickDetail('condition')}>{t(`wallet_first_condition`) + ` ` + t(`wallet_condition_required`)}</p>
          </div>
          <div className='check-items'>
            <div 
              className={classNames('checkbox', {'check':check.policy})} 
              onClick={()=>checkHandler('policy')}
            ></div>
            <p onClick={()=>clickDetail('policy')}>{t(`wallet_second_condition`) + ` ` + t(`wallet_condition_required`)}</p>
          </div>    
        </div>
        <WalletNormalButton handleButton={handleButton} title={t(`wallet_conditions_button`)} disabled={check.disabled} />
      </div>
    </div>
  )
}

export default WalletTerms;
