import CryptoJS from "crypto-js";

/**
 * 암호화 모듈
 * 
 * @param date 암호화 key 
 * @param message 암호화 시킬 메시지
 * 
 * @returns 암호화 된 값
 * 
 * @author jslee
 * @since 2022-10-21
 */
export function encrypto(date: number, message: string) {
  const date_string = date.toString();
  return CryptoJS.AES.encrypt(message, date_string).toString();
}