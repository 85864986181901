import { useState } from 'react';
import classNames from 'classnames';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import { useSelector, useDispatch } from 'react-redux';
import { RootState, setWalletPage, handleWalletModalOpen, resetWalletState, resetLoginState, LoginState } from '../../../store'; 

import { WalletHeader, WalletModal } from '../../../components'; 
import { WalletProps } from '../index'; 
import { REMOVE_SERVER_URI } from '../../../utils';


import './walletAccount.scss';
/**
 * 지갑 My Account 페이지
 * 
 * @author jslee
 * @since 2022-10-31
 */
const WalletMyAccount = ({headerTitle, normal, back, menu} : WalletProps) => {

  const { t }: {t: any} = useTranslation();
  const dispatch = useDispatch();

  const login:LoginState = useSelector((state: RootState) => state.login);
  const prevPage = useSelector((state: any) => state.wallet.prevPage);
  const userEmail = useSelector((state: any) => state.login.email);
  const userLoginType = useSelector((state: any) => state.login.category);

  /** header에 보내주는 뒤로가기 값 */
  const handleButton = () => {
    dispatch(setWalletPage({page: prevPage, prevPage: ''}));
  }

  /** 지갑 내 모달(팝업)창 오픈 여부 */
  const walletModalOpen = useSelector((state: RootState) => state.wallet.walletModalOpen);
  /** 지갑 내 모달(팝업) 종류 여러개일 시 상태값 */
  const [walletModalStatus, setWalletModalStatus] = useState<string>();

  /**
   * 지갑 My Account 페이지 메뉴 map
   * @param index 메뉴 개수
   * @param mail 첫번째 메뉴 메일 주소
   * @param platform 첫번쨰 메뉴 로그인한 플랫폼
   */
  const accountHandler = (index: number, mail?: string, platform?: string) => {
    let key = index + 1
    if (key === 1) {
      return (
        <div className="account_inner_box">
          <p className="title">{t('account_title1')}</p>
          <p className="description">
            {mail}
            <span>{t('account_description_span')} {platform?.replace(/^[a-z]/, char => char.toUpperCase())}</span>
          </p>
        </div>
      );
    } else {
      return(
        <div 
          className="account_inner_box"
          onClick={() => {
            if (key === 2) {
              dispatch(setWalletPage({page: 'change', prevPage: ''}));
            } else if (key === 3) {
              setWalletModalStatus('logout');
              handleWalletModalOpen(true);
            } else {
              setWalletModalStatus('delete');
              handleWalletModalOpen(true);
            } 
          }}
        >
          <p className="title">{t(`account_title${key+1}`)}</p>
        </div>
      );
    }
  }

  /**
   * 지갑에서 열린 모달(팝업) confirm 눌렀을 때 동작
   */
  const handleConfirmModal = () => {
    if (walletModalStatus === 'logout') {
      /**
       * TODO: 나중에 고쳐야 합니다. backend로 로그아웃 했다는 정보 전달 필요
       */
      setWalletModalStatus('');
      dispatch(resetWalletState());
      dispatch(resetLoginState());
    } else if(walletModalStatus === 'delete') {
      deleteHandler();
    }
  }

  const deleteHandler = () => {
    // console.log("login", login);
    axios.post(REMOVE_SERVER_URI, {
      email: login.email,
      accestoken: login.token
    }).then((res: any) => {
      // console.log("Delete Res", res);
      if(res.data.response === "ok") {
        setWalletModalStatus('');
        dispatch(resetWalletState());
        dispatch(resetLoginState());
      }
    })
    .catch(error => console.error(error));
  }


  /**
   * 지갑에서 열린 모달(팝업) close 눌렀을 때 동작
   * @param diff 모달 창 열고 닫기 값(boolean)
   */
  const handleCloseModal = (diff:boolean) => {
    setWalletModalStatus('');
    dispatch(handleWalletModalOpen(diff));
  }

  return (
    <div 
      className={classNames(`wallet_box`, `account`, {wallet_header : headerTitle})}
      onClick={(e) => {
        e.stopPropagation();  
      }}
    >
      {headerTitle && <WalletHeader headerTitle={headerTitle} normal={normal} back={back} menu={menu} backHandler={handleButton}/>}
      <div className="account_box">
        {[...Array(4)].map((element, index) => accountHandler(index, userEmail, userLoginType))}
      </div>
      {/* 모달 열린 상태 + 로그아웃 버튼 클릭시 */}
      {walletModalOpen || walletModalStatus === "logout" && <WalletModal description1={t('account_logout_description')} cancel={t('account_logout_button1')} confirm={t('account_logout_button2')} closeModal={handleCloseModal} confirmModal={handleConfirmModal}/>}
      
      {/* 모달 열린 상태 + 아이디 삭제 버튼 클릭시  */}
      {walletModalOpen || walletModalStatus === "delete" && <WalletModal description1={t('account_delete_description1')} description2={t('account_delete_description2')} highlight={t('account_delete_highlight')} cancel={t('account_delete_button1')} confirm={t('account_delete_button2')} closeModal={handleCloseModal} confirmModal={handleConfirmModal} />}
    </div>
  );
}

export default WalletMyAccount;