import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { GoogleLoginButton, AppleLoginButton, FacebookLoginButton } from '../../../components';

import './walletLogin.scss'
/**
 * Wallet Login Pages (SNS 로그인 관리)
 * Google 로그인 지원
 * 
 * TODO 언어팩에 따라서 텍스트 출력 되게 수정 필요
 * TODO Apple, Facebook 로그인 구현 필요
 * 
 * @author jslee
 * @since 2022-10-28
 */
function WalletLogin() {
  const { t }:{t: any} = useTranslation();

  return (
    <div 
      className={classNames(`wallet_box`, `login`)}
      onClick={(e) => {
        e.stopPropagation();  
      }}
    >
        <div className="login-header">
          <div className="login-title">{t(`wallet_login_title`)}</div>
          <div className="login-descript">{t(`wallet_login_description`)}</div>
        </div>
        <div className="login-button">
          <GoogleLoginButton />
          {/* <AppleLoginButton /> */}
          {/* <FacebookLoginButton /> */}
        </div>
    </div>
  )  
}

export default WalletLogin;