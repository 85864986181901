import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { WalletProps } from '../index';
import { WalletHeader } from '../../../components';

// import '../../scss/wallet/walletTerms.scss';
/**
 * terms and Conditions에서 Term of Service에 대한 detail을 보는 페이지
 * 
 * @author jslee
 * @since 2022-10-19
 */
const WalletCondition = ({headerTitle, normal, back, menu, backHandler} : WalletProps) => {
  const { t }:{t: any} = useTranslation();

  return (
    <div 
      className={classNames(`wallet_box`, `conditions`, {wallet_header : headerTitle} )}
      onClick={(e) => {
        e.stopPropagation();  
      }}
    >

      <WalletHeader headerTitle={headerTitle} back={back} backHandler={backHandler} />
      
      <div className='wallet-condition-body'>

        {/* 1조 */}
        <p className="terms_title">{t(`terms_content1_title`)}</p>
        <p className="terms_des_num">{t('terms_content1_description1')}</p>
        <p className="terms_des_num">{t('terms_content1_description2')}</p>
        <p className="terms_des_num">{t('terms_content1_description3')}</p>

        {/* 2조 */}
        <p className="terms_title">{t('terms_content2_title')}</p>
        <p className="terms_des">{t('terms_content2_description1')}</p>
        <p className="terms_des_num">
          {t('terms_content2_description2_1')}
          <span className='span'>{t('terms_content2_description2_span')}</span>
          {t('terms_content2_description2_2')}
          <span className="depth1">{t('terms_content2_description3')}</span>
        </p>
        <p className="terms_des_num">{t('terms_content2_description4')}</p>
        <p className="terms_des_num">{t('terms_content2_description5')}</p>
        <p className="terms_des_num">{t('terms_content2_description6')}</p>
        <p className="terms_des_num">{t('terms_content2_description7')}</p>
        <p className="terms_des_num">{t('terms_content2_description8')}</p>
        <p className="terms_des_num">{t('terms_content2_description9')}</p>
        <p className="terms_des_num">{t('terms_content2_description10')}</p>
        <p className="terms_des_num">{t('terms_content2_description11')}</p>
        <p className="terms_des_num">{t('terms_content2_description12')}</p>
        <p className="terms_des_num">{t('terms_content2_description13')}</p>
        <p className="terms_des_num">{t('terms_content2_description14')}</p>

        {/* 3조 */}
        <p className="terms_title">{t('terms_content3_title')}</p>
        <p className="terms_des">{t('terms_content3_description1')}</p>
        <p className="terms_des_num">{t('terms_content3_description2')}</p>
        <p className="terms_des_num">{t('terms_content3_description3')}</p>
        <p className="terms_des_num">{t('terms_content3_description4')}</p>
        <p className="terms_des_num">{t('terms_content3_description5')}</p>

        {/* 4조 */}
        <p className="terms_title">{t('terms_content4_title')}</p>
        <p className="terms_des_num">{t('terms_content4_description1')}</p>
        <p className="terms_des_num">
          {t('terms_content4_description2')}
          <span className="depth1">{t('terms_content4_description2_1')}</span>
          <span className="depth2">{t('terms_content4_description2_2')}</span>
        </p>
        <p className="terms_des_num">
          {t('terms_content4_description3')}
          <span className="depth1">{t('terms_content4_description3_1')}</span>
        </p>
        <p className="terms_des_num">{t('terms_content4_description4')}</p>
        <p className="terms_des_num">{t('terms_content4_description5')}</p>
        <p className="terms_des_num">{t('terms_content4_description6')}</p>
        <p className="terms_des_num">{t('terms_content4_description7')}</p>
        <p className="terms_des_num">{t('terms_content4_description8')}</p>
        <p className="terms_des_num">{t('terms_content4_description9')}</p>
        <p className="terms_des_num">{t('terms_content4_description10')}</p>

        {/* 5조 */}
        <p className="terms_title">{t('terms_content5_title')}</p>
        <p className="terms_des_num">
          {t('terms_content5_description1')}
          <span className='depth1'>{t('terms_content5_description1_1')}</span>
        </p>
        <p className="terms_des_num">
          {t('terms_content5_description3')}
          {/* 5조 table 있던 곳 */}
          <span className="depth1">{t('terms_content5_table_body1_title1')}</span>
          <span className="depth2">
            {t('terms_content5_table_header2')} : {t('terms_content5_table_body1_description1')}<br />
            {t('terms_content5_table_header3')} : {t('terms_content5_table_body2_3')}<br />
          </span>
          <span className="depth1">{t('terms_content5_table_body1_title2')}</span>
          <span className="depth2">
            {t('terms_content5_table_header2')} : {t('terms_content5_table_body1_description2')}<br />
            {t('terms_content5_table_header3')} : {t('terms_content5_table_body2_3')}<br />
          </span>
          <span className="depth1">{t('terms_content5_table_body1_title3')}</span>
          <span className="depth2">
            {t('terms_content5_table_header2')} : {t('terms_content5_table_body1_description3')}<br />
            {t('terms_content5_table_header3')} : {t('terms_content5_table_body2_3')}<br />
          </span>
          <span className="depth1">{t('terms_content5_table_body1_title4')}</span>
          <span className="depth2">
            {t('terms_content5_table_header2')} : {t('terms_content5_table_body1_description4')}<br />
            {t('terms_content5_table_header3')} : {t('terms_content5_table_body2_3')}<br />
          </span>
          <span className="depth1">{t('terms_content5_table_body1_title5')}</span>
          <span className="depth2">
            {t('terms_content5_table_header2')} : {t('terms_content5_table_body1_description5')}<br />
            {t('terms_content5_table_header3')}<br />
            1{t('terms_content5_table_header4')} : {t('terms_content5_table_body2_1')}<br />
            2{t('terms_content5_table_header4')} : {t('terms_content5_table_body2_2')}<br />
            3{t('terms_content5_table_header4')} : {t('terms_content5_table_body2_3')}<br />
          </span>
          <span className="depth1">{t('terms_content5_table_body1_title6')}</span>
          <span className="depth2">
            {t('terms_content5_table_header2')} : {t('terms_content5_table_body1_description6')}<br />
            {t('terms_content5_table_header3')} : {t('terms_content5_table_body2_3')}<br />
          </span>
          <span className="depth1">{t('terms_content5_table_body1_title7')}</span>
          <span className="depth2">
            {t('terms_content5_table_header2')} : {t('terms_content5_table_body1_description7')}<br />
            {t('terms_content5_table_header3')} : {t('terms_content5_table_body2_3')}<br />
          </span>
          <span className="depth1">{t('terms_content5_table_body1_title8')}</span>
          <span className="depth2">
            {t('terms_content5_table_header2')} : {t('terms_content5_table_body1_description8')}<br />
            {t('terms_content5_table_header3')} : {t('terms_content5_table_body2_3')}<br />
          </span>
          <span className="depth1">{t('terms_content5_table_body1_title9')}</span>
          <span className="depth2">{t('terms_content5_description4_1')}</span>
        </p>

        <p className="terms_des_num">
          {t('terms_content5_description5')}
          <span className="depth1">{t('terms_content5_description6')}</span>
        </p>

        {/* 6조 */}
        <p className="terms_title">{t('terms_content6_title')}</p>
        <p className="terms_des">{t('terms_content6_description1')}</p>
        <p className="terms_des_num">{t('terms_content6_description2')}</p>
        <p className="terms_des_num">{t('terms_content6_description3')}</p>
        <p className="terms_des_num">{t('terms_content6_description4')}</p>
        <p className="terms_des_num">{t('terms_content6_description5')}</p>
        <p className="terms_des_num">{t('terms_content6_description6')}</p>
        <p className="terms_des_num">{t('terms_content6_description7')}</p>
        <p className="terms_des_num">{t('terms_content6_description8')}</p>

        {/* 7조 */}
        <p className="terms_title">{t('terms_content7_title')}</p>
        <p className="terms_des_num">{t('terms_content7_description1')}</p>
        <p className="terms_des_num">{t('terms_content7_description2')}</p>
        <p className="terms_des_num">{t('terms_content7_description3')}</p>

        {/* 8조 */}
        <p className="terms_title">{t('terms_content8_title')}</p>
        <p className="terms_des_num">{t('terms_content8_description1')}</p>
        <p className="terms_des_num">{t('terms_content8_description2')}</p>

        {/* 9조 */}
        <p className="terms_title">{t('terms_content9_title')}</p>
        <p className="terms_des_num">{t('terms_content9_description1')}</p>
        <p className="terms_des_num">{t('terms_content9_description2')}</p>
        <p className="terms_des_num">{t('terms_content9_description3')}</p>

        {/* 공고일자 */}
        <p className="terms_bottom">{t('terms_bottom')}</p>

      </div>
    </div>
  );
};

export default WalletCondition;