import classNames from "classnames";
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { WalletHeader, WalletNormalButton } from "../../../components";
import { WalletProps } from "../index";
import './walletSend.scss';

const WalletSend = ({headerTitle, normal, back, menu, backHandler} : WalletProps) => {

  const dispatch = useDispatch();
  const { t }:{ t:any } = useTranslation();

  const handleButton = () => {
    console.log("보내줘!!");
  }

  return (
    <div
      className={classNames(`wallet_box`, `send`, `wallet_header`)}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <WalletHeader headerTitle={headerTitle} back={back} backHandler={handleButton} />
      <div className="send_content">
        {/* send to */}
        <div className="send_to">
          <p>To</p>
          <div className="input-icon">
            <i className="icon"/>
            <input type="text" placeholder="Enter Address" />
          </div>
        </div>

        {/* send amount */}
        <div className="send_amount">
          <p>Amount</p>
          <div className="amount-wrapper">
            <input type="text" className="amount_input" placeholder="ZEMI" />
            <div className="input-button">
              <p>Max</p>
            </div>
          </div>
          <div className="balance">
            <p className="balance-title">My Balance</p>
            <p className="balance-value">100.937823</p>
          </div>
          <p className="balance-warnning">
            <span>ⓘ </span>
            Less than the minimum transfer quantity.
          </p>
          <div className="min-amount">
            <p className="min-title">Min Amount</p>
            <p className="min-value">1 <span>ZEMI</span></p>
          </div>
        </div>
        <WalletNormalButton handleButton={handleButton} title='Next'/>
      </div>
    </div>
  )
}

export default WalletSend;