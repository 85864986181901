import { WalletMainButtonProps } from "../../../pages";

/**
 * 지갑 메인 상단 버튼 Send/Receive/Convert
 * 
 * @param title 버튼 내용
 * @returns 
 */
const WalletMainButton = ({ title }: WalletMainButtonProps) => {
  return (
    <div className="wallet_main_button">
      <div className="image"></div>
      <p className="title">{title}</p>
    </div>
  );
}

export default WalletMainButton;