import { WalletButtonProps } from "../../../pages";
import "./walletButton.scss";

const WalletNormalButton = ({ disabled, handleButton, title }: WalletButtonProps) => {
  return (
    <div
      className={disabled ? "wallet_button disabled" : "wallet_button"}
      onClick={() => {
        if (disabled) {
          return false;
        } else {
          handleButton();
        }
      }}
    >
      <p>{title}</p>
    </div>
  );
}

export default WalletNormalButton;