/* import : S */
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { setWalletPage } from '../../../store';
import {WalletNormalButton} from '../../../components';
import { useTranslation } from 'react-i18next';

/* import : E */

/**
 * 지갑을 열었을 때 제일 처음 보이는 화면, Get Start 화면의 컴포넌트
 * @param WalletProps - { headerTitle: string, normal: boolean, back: boolean, menu: boolean }
 * 
 * @author jslee
 * @since 2022-10-28
 */
const WalletStart = () => {
  const dispatch = useDispatch();
  const { t }:{ t:any } = useTranslation();
  /**
   * login page로 이동
   * 
   * @author jslee
   * @since 2022-10-19
   */
  const handleButton = () => {
    dispatch(setWalletPage({page:'login', prevPage: ''}));
  }

  return (
    // {/* 클래스명 규칙 "기본 wallet_box / 헤더가 있을 경우 CSS 경우 wallet_header 추가 / 페이지명" */}
    <div 
      className={classNames(`wallet_box`, `start`)}
      onClick={(e) => {
        e.stopPropagation();  
      }}
    > 
      <div className="logo">
        <div className="image"></div>
        <p className="title">ZEMI Wallet</p>
      </div>
      <WalletNormalButton handleButton={handleButton} title={t(`wallet_start_button`)}/>
    </div>
  );
}

export default WalletStart;