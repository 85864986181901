import { WalletServiceButtonProps } from "../../../pages"; 

const ServiceButton = ({ title, button, handleButton }: WalletServiceButtonProps) => {
  return (
    <div className="service_box">
      <p className="title">{title}</p>
      <div className="button" onClick={() => {
        handleButton();
      }}>
        <p className="button_title">{button}</p>
      </div>
    </div>
  );
}

export default ServiceButton;