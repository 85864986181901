import classNames from 'classnames';
import axios from 'axios';
import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { encrypto, VALIDATE_SERVER_URI, setLoginData } from '../../../utils'; 
import { RootState, LoginState, setChangePassword, setWalletPage } from '../../../store';

import '../Password/walletPassword.scss';
import { WalletHeader } from '../../../components';

/**
 * 지갑의 비밀번호에 관한 페이지 
 * 
 * TODO change password에 대한 처리를 어떻게 할지 flow 및 구현 필요
 * TODO 추후 비밀번호 입력에 관한 flow 및 기능 구현 필요
 * 
 * @author jslee
 * @since 2022-10-28
 */
const WalletChangePassword = () => {

  const { t }: {t: any} = useTranslation();
  const dispatch = useDispatch();

  const login:LoginState  = useSelector((state: RootState) => state.login);

  const [password, setPassword] = useState('');
  const [status, setStatus] = useState(false);

  /* 첫번째 비밀번호를 모두 입력하면 두번째 비밀번호로 이동하기 위한 hook  */
  useEffect(() => {
    if (password.length === 6) {
      changePassword();
    }
  }, [password]);


  /**
   * 입력한 패스워드가 맞는지 확인
   * 
   * @since 2022-10-28
   * @author jslee
   */
  const changePassword = async() => {
    const date = new Date();
    let e_password = encrypto(date.getTime(), password);
    
    await axios.post(VALIDATE_SERVER_URI, {
      accestoken: login.token,
      password: e_password,
      datetime: date
    })
    .then(async(res:any) => {
      // console.log("res",res);
      if(res.data.response === "ok" && res.data.access_token) {
        await dispatch(setChangePassword({inputPassword: password, isRepwd: true}));
        await setLoginData('password', dispatch, res, login.email, login.category);
      } else {
        setStatus(true);
        setPassword('');
      }
    })
    .catch(error => console.error(error));
  }

  /**
   * password 입력 버튼(pin 버튼)
   * 
   * @param index 
   * @returns 로그인 할 때 보이는 숫자패드 버튼
   * 
   * @author jslee
   * @since 2022-10-28
   */
  const contentHandler = (index: number) => {
    let key = index as number + 1;
    return (
      <div
        className={`number_pad_${key}`}
        onClick={() => {
          if (key === 10) {
            setPassword('');
          } else if (key === 11) {
            setPassword(password.concat('0'));
          } else if (key === 12) {
            setPassword(password.slice(0, -1));
          } else {
            setPassword(password.concat(String(key)));
          }
      }}>
        {key === 10 || key === 12 ? '' : key === 11 ? '0' : key}
      </div>
    );
  }

  /**
   * 상단 password *****로 보여주는 부분
   * 
   * @param index 몇 번째 점인지 index(0부터 시작해서 key는 + 1시켜준다.)
   * @returns 로그인할 때 상단의 dots
   * 
   * @author jslee
   * @since 2022-10-28
   */
  const dotHandler = (index: number) => {
    let key = index as number + 1;
    return (
      <div className={classNames('dot', {on : password.length >= key})}></div>
    );
  }

  const backHandler = () =>{
    dispatch(setWalletPage({page:'myAccount', prevPage:'menu'}));
  }

  return(
    <div 
      className={classNames(`wallet_box`, `password`, `password-header`, `wallet_header`)}
      onClick={(e) => {
        e.stopPropagation();  
      }}
    >
      <WalletHeader back={true} backHandler={backHandler}/>
      <div className='content1'>
        <p className="title">{t('change_password_title')}</p>
        <p className={classNames(`description`, {"warnning": status})}>
        {status ? t('change_password_description2') : t('change_password_description1') }
        </p>
        <div className="password_dot">
          {[...Array(6)].map((element, index) => dotHandler(index))}
        </div>
      </div>
      <div className="number_pad">
          {[...Array(12)].map((element, index) => contentHandler(index))}
        </div>
    </div>
  );
}

export default WalletChangePassword;