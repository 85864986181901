import { useTranslation } from 'react-i18next';
import './walletSnsButton.scss';
/**
 * apple login button component
 * TODO i need to use the library to fix it.
 * TODO 언어팩에 따라서 텍스트 출력 되게 수정 필요
 * 
 * @author jslee
 * @since 2022-10-18
 */
function AppleLoginButton() {
  const { t }: {t: any} = useTranslation();

  const responseHandler = (response: any) =>{
    console.log(response);
  }
  return (
    <div
        className="wallet-login-button apple"
        onClick={responseHandler}
      >
        <div className='icon'></div>
        <p>{t(`wallet_login_apple`)}</p>
    </div>
  );
};

export default AppleLoginButton;