const Nft = () => {
    return(
        <div className="nft scroll">
            <p>nft</p>
            <p>nft</p>
            <p>nft</p>
            <p>nft</p>
            <p>nft</p>
        </div>
    );
}

export default Nft;