import classNames from "classnames";

import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';

import { WalletHeader } from "../../../components";

import { WalletProps } from "../index";

import'./walletTokens.scss';

interface TokenListProps {
  date: string;
  tx: string;
  amount: string;
  to: string;
  from: string;
}

const TokenList = ({date, tx, amount, to, from} : TokenListProps) => {
  return(
    <div className="token_list">
      <p className="date">{date}</p>
      <div className="list_inner">
        <p className="tx">
          Tx hash
          <span>{tx}</span>
        </p>
        <p className="amount">
          Amount
          <span>{amount}</span>
        </p>
      </div>
      <div className="list_inner">
        <p className="to">
          To
          <span>{to}</span>
        </p>
        <p className="from">
          From
          <span>{from}</span>
        </p>
      </div>
    </div>
  );
}

const WalletTokens = ({ headerTitle, normal, back, menu, backHandler }: WalletProps) => {

  const dispatch = useDispatch();
  const { t }:{ t:any } = useTranslation();

  return(
    <div
      className={classNames(`wallet_box`, `tokens`, `wallet_header`)}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <WalletHeader headerTitle={headerTitle} normal={normal} back={back} menu={menu} backHandler={backHandler}/>
      <div className="token_container">
        <div className="token_box">
          <div className="token">
            <div className="image"></div>
            <div className="token_info">
              <p className="title">ZEMI</p>
              <p className="description">BZ</p>
            </div>
          </div>
          <p className="amount">100.385474</p>
        </div>
        <div className="history_button">
          <p>Transaction History</p>
          <div className="arrow"></div>
        </div>
        <div className="list_box">
          <TokenList date={t('YYYY-MM-DD HH:MM:SS (UTC)')} tx={t('Nik3248…')} amount={t('3.484383')} to={t('Nik3248…')} from={t('Nik3248…')}/>
          <TokenList date={t('YYYY-MM-DD HH:MM:SS (UTC)')} tx={t('Nik3248…')} amount={t('3.484383')} to={t('Nik3248…')} from={t('Nik3248…')}/>
          <TokenList date={t('YYYY-MM-DD HH:MM:SS (UTC)')} tx={t('Nik3248…')} amount={t('3.484383')} to={t('Nik3248…')} from={t('Nik3248…')}/>
          <TokenList date={t('YYYY-MM-DD HH:MM:SS (UTC)')} tx={t('Nik3248…')} amount={t('3.484383')} to={t('Nik3248…')} from={t('Nik3248…')}/>
        </div>
      </div>
    </div>
  );
}

export default WalletTokens;