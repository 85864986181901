import { useEffect, useState } from 'react';

import { Routes, Route, useLocation, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Header, Footer, MetaTag } from './components';
import { Platform, Nft, Studio, Support, NotFound } from './pages';

import './assets/css/reset.css';
import './assets/css/app.scss';
import { history } from './utils';
import { initWallet } from './store';

const Layout = () => {

  const { t } = useTranslation();

  // Now url
  const location = useLocation();

  // Header Menu
  const [title, setTitle] = useState([
    { id: 1, title: t("header1"), link: "/" },
    { id: 2, title: t("header2"), link: "/nft" },
    { id: 3, title: t("header3"), link: "/studio" },
    { id: 4, title: t("header4"), link: "/support" },
  ]);

  return (
    <div className="App">
      {/* header */}
      <Header
        title={title}
        location={location.pathname}
      />
      {/* //header */}

      {/* container */}
      <div className="container">
        <div className="container_scroll">

          <Outlet />

          {/* footer */}
          <Footer />
          {/* //footer */}

        </div>
      </div>
      {/* //container */}

    </div>
  );
}

const App = () => {

  const { t }:{ t:any } = useTranslation();

  const check = useSelector((state: any) => state.login.token);
  const currentPage = useSelector((state: any) => state.wallet.page);
  const dispatch = useDispatch();

  const handleResize = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`); 
  };

  useEffect(() => {
    const unlistenHistoryEvent = history.listen(({ action }) => {
      console.log("[history action] action = ", action);
      if (action === "POP") {
        if (check) {
          // console.log("로그인 한 사용자");
          dispatch(initWallet(true));
        } else {
          dispatch(initWallet(false));
        }
      }
      if (action === "PUSH") {
        if (check) {
          // console.log("로그인 한 사용자");
          dispatch(initWallet(true));
        } else {
          dispatch(initWallet(false));
        }
      }
    });
    return unlistenHistoryEvent;
  }, [check]);

  useEffect(() => {
    // console.log()
    console.log("reload", window.performance.getEntries()[0]);
    if ((window.performance.getEntries()[0] as PerformanceNavigationTiming).type === "reload") {
      console.log("리로딩!!!!");
      if (check) {
        // console.log("로그인 한 사용자");
        dispatch(initWallet(true));
      } else {
        dispatch(initWallet(false));
      }
    }
  }, []);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
  
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <MetaTag />
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Platform />} />
          <Route path="/nft" element={<Nft />} />
          <Route path="/studio" element={<Studio />} />
          <Route path="/support" element={<Support />} />
          <Route path="/*" element={<NotFound />} />
        </Route>
        {/* error page 처리 */}
      </Routes>
    </>
  );
}

export default App;
