const Studio = () => {
    return(
        <div className="main scroll">
            <p>studio</p>
            <p>studio</p>
            <p>studio</p>
            <p>studio</p>
            <p>studio</p>
        </div>
    );
}

export default Studio;