import classNames from 'classnames';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { RootState, connectRouteSetting, connectModalSetting } from '../../store';

import { Languages, languages } from '../../assets/locales/i18n';

const Footer = () => {

  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();

  const [languageOpen, setLanguageOpen] = useState(false);

  const modalOpen = useSelector((state: RootState) => state.modal.open);

  useEffect(() => {
    console.log(i18n.language);
  }, [i18n.language]);

  const handlerLanguage = (lang: Languages) => {
    i18n.changeLanguage(lang);
    sessionStorage.setItem('language', lang);
    // getItem('language');
  }

  return (
    <footer>

      <div className="footer_inner">
        <div className="footer_box1">
          <div className="footer_box1_inner">
            {/* <a href="https://exais.io/">EXAIS</a> */}
            {/* <Link
              onClick={() => {
                window.scrollTo(0, 0);
                dispatch(connectRouteSetting(""));
              }}
              to={"/"}
            >
              Notice
            </Link> */}
            {/* <Link
              onClick={() => {
                window.scrollTo(0, 0);
                dispatch(connectRouteSetting(""));
              }}
              to={"/"}
            >
              Support
            </Link> */}
            <a 
              // onClick={(e) => {
              //   e.preventDefault();
              //   dispatch(connectModalSetting(!modalOpen));
              // }}
              href="https://medium.com/@nextor2023"
              target="_blink"
            >Notice</a>
            <a href='mailto:hiru1986@nextor.ai'>Support</a>
            <a href="https://polygonscan.com/" target="_blink">Explorer</a>
            <a href='https://medium.com/@nextor2023' target="_blink">FAQ</a>
          </div>
          <p className="copyright">© 2022 ZEMI Platform. All rights reserved.</p>
        </div>

        <div className="footer_box2">
          {/* SNS */}
          <div className="sns_box">
            <div className="medium" onClick={() => {
              window.open('https://medium.com/@nextor2023');
            }}></div>
            <div className="facebook" onClick={() => {
              window.open('https://www.facebook.com/people/Nextor/100087460815256/');
            }}></div>
            <div className="twitter" onClick={() => {
              window.open('https://twitter.com/nextor2023');
            }}></div>
            <div className="discord" onClick={() => {
              window.open('https://discord.com/invite/ZcYHnUF4ad');
            }}></div>
          </div>

          {/* language */}
          <div
            className={classNames(`language_box`, { on: languageOpen })}
            onClick={() => {
              setLanguageOpen(!languageOpen);
            }}
          >
            <ul className="language_box_inner">
              {/* {LanguageMap} */}
              {languages.map((lang: any) => (
                <li
                  key={lang}
                  className={classNames({ on: lang.substr(0, 2) === i18n.language })}
                  onClick={() => handlerLanguage(lang)}
                >
                  {
                    lang.substr(0, 2) === 'ko'
                      ? "Korean"
                      : lang.substr(0, 2) === 'en'
                        ? "English"
                        : "Language"
                  }
                </li>
              )
              )}
            </ul>
            <p>
              {
                i18n.language === 'ko' || i18n.language === 'ko-KR'
                  ? "Korean"
                  : i18n.language === 'en' || i18n.language === 'en-US'
                    ? "English"
                    : "Language"
              }
            </p>
            <div
              className={classNames(`language_arrow`, { on: languageOpen })}
            ></div>
          </div>
        </div>
      </div>

    </footer>
  );
}

export default Footer;