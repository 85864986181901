import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { useSelector, useDispatch } from 'react-redux';
import { RootState, setWalletPage } from '../../../store';

import { WalletProps } from '../index'; 
import { WalletHeader } from '../../../components';

import './walletMenu.scss';
/**
 * WalletMenu 페이지
 * 
 * @author jslee
 * @since 2022-10-31
 */
const WalletMenu = ({headerTitle, normal, back, menu} : WalletProps) => {

  const { t }: { t: any} = useTranslation();
  const dispatch = useDispatch();

  // menu를 관리
  const menuArr = [
    {page: 'myAccount', title: 'wallet_menu_subtitle1'},
    // {page: 'connected', title: 'wallet_menu_subtitle2'},
    {page: 'condition', title: 'wallet_menu_subtitle3'},
    {page: 'policy', title: 'wallet_menu_subtitle4'},
  ];

  /** 지갑 메뉴 Open 여부 체크 */
  const walletMenuOpen = useSelector((state: RootState) => state.wallet.walletMenuOpen);

  /**
   * 지갑 메뉴 map (My Account/Connected Service/Terms Conditions/Privacy Policy)
   * TODO 현재 connected는 숨겨 놓은 상태 나중에 zemi 작업이 완료 되면 수정 필요
   * 
   * @param index 메뉴 개수
   * 
   * @author jslee
   * @since 2022-10-31
   */
  const walletMenuHandler = (index: number) => {
    return (
      <div 
        className="wallet_menu"
        onClick={() =>dispatch(setWalletPage({page: menuArr[index].page, prevPage:'menu'}))}
      >
        <p>{t(menuArr[index].title)}</p>
      </div>
    );
  }

  return (
    <div 
      className={classNames(`wallet_box`, `menu`, {wallet_header : headerTitle})}
      onClick={(e) => {
        e.stopPropagation();  
      }}
    >

      {headerTitle && <WalletHeader headerTitle={headerTitle} normal={normal} back={back} menu={menu}/>}

      <div 
        className={classNames("wallet_menu_open", {on : walletMenuOpen})} 
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {[...Array(menuArr.length)].map((element, index) => walletMenuHandler(index))}
      </div>

    </div>
  );
}

export default WalletMenu;