import { useState } from 'react';
import ReactPlayer from 'react-player';
import { useTranslation } from 'react-i18next';

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

import { useSelector, useDispatch } from 'react-redux';

import { RootState, connectModalSetting } from '../../store';

import './platform.scss';

const Platform = () => {

  const { t }:{ t:any } = useTranslation();
  const dispatch = useDispatch();

  // modal open 여부
  const modalOpen = useSelector((state: RootState) => state.modal.open);

  // Video playing check
  const [isPlaying, setIsPlaying] = useState(null);

  // Video map
  const [video, setVideo] = useState([
    { id: 1, video: 'UbJUrAIBbzc' },
    // { id: 2, video: 'hdgjFWSvgiY' },
    // { id: 3, video: '5dppORtI6RA' },
    // { id: 4, video: 'fj8ReY0HxWc' },
    // { id: 5, video: 'JUzPQ0JalHE' },
  ]);

  const videoMap = video.map((data: any) => (
    <SwiperSlide className="swiper-slide" key={data.id}>
      <ReactPlayer
        url={'https://www.youtube.com/embed/' + data.video}
        controls={true}
        onPlay={() => {
          setIsPlaying(data.id);
        }}
        playing={isPlaying === data.id}
        width={"100%"}
        height={"100%"}
      />
    </SwiperSlide>
  ));

  return (
    <div className="platform">
      {/* content1 */}
      <div className="content1">
        <div className="content1_box">
          <p className="title">
            {t('platform_content1_title')}
            <span>{t('platform_content1_title_1')}</span>
          </p>
          <p className="description">{t('platform_content1_description')}</p>
          <div className="button_box">
            <div 
              className="apple_store_button"
              onClick={() => {
                dispatch(connectModalSetting(!modalOpen));
              }}
            ></div>
            <div 
              className="google_play_button"
              onClick={() => {
                dispatch(connectModalSetting(!modalOpen));
              }}
            ></div>
          </div>
        </div>

        {/* swiper */}
        <Swiper
          className="mySwiper"
          modules={[Pagination]}
          // loop={true}
          pagination={{ clickable: true }}
          slidesPerView={1}
          onSlideChange={() => {
            setIsPlaying(null);
          }}
        >
          {videoMap}
        </Swiper>
      </div>

      {/* content2 */}
      <div className="content2">
        <div className="content2_box">
          <p className="title">{t('platform_content2_title')}</p>
          <p className="description">{t('platform_content2_description')}</p>
        </div>
        <div className="content2_box">
          <p className="title">{t('platform_content2_table_th1')}
            <a className="description" href="http://nextor.ai/" target="_blank" >{t('platform_content2_table_td1')}</a>
          </p>
          <p className="title">{t('platform_content2_table_th2')}
            <span className="description">{t('platform_content2_table_td2')}</span>
          </p>
          <p className="title">{t('platform_content2_table_th3')}
            <span className="description">2023-03-31</span>
          </p>
          <p className="title">
            {t('platform_content2_table_th4')}
            <div className="platform_box">
              <span className="app_store"></span>
              <span className="google_play"></span>
            </div>
          </p>
          <p className="title">{t('platform_content2_table_th5')}
            <span className="description">{t('platform_content2_table_td5')}</span>
          </p>
        </div>
      </div>

      {/* content3 */}
      <div className="content3">
        <div className="content3_box">
          <p className="title">{t('platform_content3_title1')}</p>
          <p className="description">{t('platform_content3_description1')}</p>
        </div>
        <div className="content3_box">
          <p className="title">{t('platform_content3_title2')}</p>
          <p className="description">{t('platform_content3_description2')}</p>
        </div>
        <div className="content3_box">
          <p className="title">{t('platform_content3_title3')}</p>
          <p className="description">{t('platform_content3_description3')}</p>
        </div>
        <div className="content3_box">
          <p className="title">{t('platform_content3_title4')}</p>
          <p className="description">{t('platform_content3_description4')}</p>
        </div>
      </div>

      {/* content4 */}
      <div className="content4"
        onClick={() => {
          window.open('https://twitter.com/nextor2023', '_blank');
        }}
      >
        <p className="title">이벤트 제목 들어가는 자리입니다</p>
        <p className="description">이벤트 상세 내용 입력 칸입니다.<br />어쩌구 저쩌구 블라블라블라 김수한무 거북이와 두루미 삼천갑자 동방삭...</p>
        <p className="date">2022. 12. 01 ~ 2022. 12. 30</p>
      </div>
    </div>
  );
}

export default Platform;